<template>
	<div>
		<CCard class="p-4">
		<CRow>
			
			<CCol lg="6">
					<h5>List of DR's/ Booking #{{dataList.booking_no}}</h5>
			</CCol>
	     		 <CCol lg="6">
					   <CButton size="sm" style="float: right;" :class="'float-lg-right block '+showCollapse ? 'collapsed' : null"
			                 :aria-expanded="showCollapse ? 'true' : 'false'"
			                 aria-controls="collapse1"
			                 @click="showCollapse = !showCollapse"
			                 color="info"><i class="fa fa-search"></i>Filter
							 <i :class="showCollapse ? 'fa fa-caret-up' : 'fa fa-caret-down'"></i>
						</CButton>
			
			        <CButton 
						v-if="config.getPermission('dr').create"
						size="sm" style="margin-right: 10px" 
						color="success" :href="'#/booking/'+$route.params.id+'/create_dr'" target="_blank"
						class="float-lg-right"><i class="fa fa-plus"></i>Add DR
					</CButton>
					<CButton 
			            size="sm" style="float: right; margin-right: 10px" :class="'float-lg-right block '" 
			        	 @click ="backToTable()"
	    				color="secondary">
						 <i class="fa fa-arrow-left"></i>Back
					</CButton>
		      		<!-- <CButtonGroup size="sm" class="float-lg-right">
			          <CButton color="success" :href="'#/booking/'+$route.params.id+'/create_dr'"><i class="fa fa-plus"></i> Add DR</CButton>
			          <CButton :class="showCollapse ? 'collapsed' : null"
			                 :aria-expanded="showCollapse ? 'true' : 'false'"
			                 aria-controls="collapse1"
			                 @click="showCollapse = !showCollapse"
			                 color="info"><i :class="showCollapse ? 'fa fa-caret-up' : 'fa fa-caret-down'"></i></CButton>
			        </CButtonGroup>  -->
			    </CCol>
		</CRow>
		<CRow> 
				<CCol lg="12">
					<CCollapse id="collapse1" :show="showCollapse" class="mt-2">
				        <CCard class="p-4">
				          <Search @depot-search-query="search"/>
				        </CCard>
				      </CCollapse> 
				</CCol>
		</CRow> 
		<hr>

		<CRow>		
			
		      <CCol lg="12">  
				  <div class="table-responsive-sm table-responsive-md">
				<CDataTable 
				   :loading="isLoading"
			      	:items="dataList.data"
			      	:fields="fields"
					hover
					striped
					outlined

					
			    >  
				
			    	<template #status="{item}">
						 <td style="padding: 5px; width: 100px; text-transform:uppercase;">
						<CBadge 
						 style="margin-top:10px"		
							:color="getBadge(item.status)">{{item.status}}</CBadge>
							 </td>
			      	</template>
			      	<template #action="{item}">
				        <td style="padding: 5px; width: 100px">
				        	<CDropdown 
				                toggler-text="Select"  
								size="sm"
				                color="info"
				            >
				            	<CDropdownItem v-if="config.getPermission('dr').view" @click="viewModal(item)"><i class="fa fa-eye"> View </i></CDropdownItem>  
				            	<CDropdownItem v-if="config.getPermission('dr').update" @click="updateModal(item)"><i class="fa fa-pencil-square-o"> Update </i></CDropdownItem>  
				                 <CDropdownItem v-if="config.getPermission('dr').delete" @click="deleteRow(item)"><i class="fa fa-trash"> Delete</i></CDropdownItem> 
				            </CDropdown>
				        </td>
			      	</template>
			    </CDataTable> 
			    <CPagination
		          	:active-page.sync="currentPage"
		          	:pages="Math.ceil(dataList.total / 20)"
		          	:activePage="currentPage"
		          	@update:activePage="updatePage"
		        />
				  </div>
		    </CCol> 
	    </CRow> 
		</CCard>
	</div>
</template>
<script> 
import config from '../../config.js';
import axios from '../../axios';
import Swal from 'sweetalert2';
import Search from './search'
import Datepicker from 'vuejs-datepicker';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'; 
import moment from 'moment';

export default {
	mounted(){  
		this.getVendor();
		this.getDepot();
		this.getData();  
	},
	data(){
		return{ 
			isLoading: false,
			config,
			formModal: false,
			showViewModal: false,
			dataList: {
				data: [],
				total: 0,
				booking_no: ""
			},  
			
			depotName: "", 
			fields: [
				{
					key: 'dr_id', 
					label: 'DR ID'
				},
				{
					key: 'customer_name', 
					label: 'Customer'
				},
				{
					key: 'delivery_date', 
					label: 'Delivery Date'
				},
				{
					key: 'delivered_by', 
					label: 'Delivered By'
				},
				{
					key: 'contact_person', 
					label: 'Contact Person'
				}, 
				{
					key: 'department', 
					label: 'Department'
				},
				{
					key: 'action',
					label: 'Action',
					_style: { width: '20px'}
				}
			],
			currentPage: 1,  


			editMode: false,
			selectedRow: {},
			showCollapse: false,
			filter: {
				customer_id:"",
				dr_id: "",
	            delivery_date_from: "",
	            delivery_date_to: "",
	            department: "",
			},
			vendorList: {
				data: []
			},
			depotList: {
				data:[]
			},
			customerList: {
				data:[]
			},
		}
	},
	name: 'Tables',
	components: {Search, Datepicker, vSelect},
	methods: {
		reloadData(){
			this.getData();  
		},
		 backToTable(){
	    	this.$router.push('/main/view_booking/'+this.$route.params.id)
	    },
		 
		 getBadge (status) {
    	return status === 'completed' ? 'success'
       		 : status === 'under review' ? 'dark'
         	 : status === 'approved' ? 'warning'
			 : status === 'cancelled' ? 'secondary'
			 : status === 'delivered' ? 'primary'
         	 : status === 'dispatched' ? 'info'
             : status === 'declined' ? 'danger' : 'primary'
    },
		updatePage(data){
			this.currentPage = data;
			this.getData();
		},     

	    toggleModal(){
	    	this.formModal = !this.formModal;
	    }, 

	    search(event){
	    	this.filter = event; 
	    	this.getData();  
	    }, 

	    getData(){   

			this.isLoading=true;

	    	if(this.filter.delivery_date_from != ""){ 
	    		this.filter.delivery_date_from = moment(this.filter.delivery_date_from.getTime()).startOf('day') / 1000;
	    	}

	    	if(this.filter.delivery_date_to != ""){ 
	    		this.filter.delivery_date_to = moment(this.filter.delivery_date_to.getTime()).startOf('day') / 1000;
	    		this.filter.delivery_date_to += 86399;
	    	}

	    	var customer_id = this.filter.customer_id;
	    	if(customer_id == "all"){
	    		customer_id = "";
	    	}

	    	axios.get(config.api_path+'/deliveryReceipt/list?dr_id='+this.filter.dr_id+'&customer_id='+this.filter.customer_id+'&delivery_date_from='+this.filter.delivery_date_from+'&delivery_date_to='+this.filter.delivery_date_to+'&department='+this.filter.department+'&limit=20&booking_id='+this.$route.params.id)
	    	.then(response => {
	    		this.dataList = response.data; 
	    		this.dataList.data = this.dataList.data.map((value, index)=>{
	    			// if(value.contact_person){
	    			// 	value.contact_person = value.contact_person
	    			// }
	    			// else{
	    			// 	value.contact_person = "N/A"
	    			// }

	    			// if(value.department){
	    			// 	value.department = value.department
	    			// }
	    			// else{
	    			// 	value.department = "N/A"
	    			// }

	    			// if(value.delivered_by){
	    			// 	value.delivered_by_label = value.delivered.name
	    			// }
	    			// else{
	    			// 	value.delivered_label = "N/A"
	    			// }

	    			// if(value.customer_id){
	    			// 	value.customer_id_label = value.customer.customer_name
	    			// }
	    			// else{
	    			// 	value.customer_id_label = "N/A"
	    			// }

					// if(value.delivery_date){
	    			// 	value.delivery_date_label = moment.unix(value.delivery_date).format('MMMM DD, YYYY')
	    			// }
	    			// else{
	    			// 	value.delivery_date_label = "N/A"
	    			// }
					value.department = value.department ??'' ;
					value.contact_person = value.contact_person ??'' ;
					value.customer_name = value.customer_name;
	    			value.remarks = value.remarks != "" ? value.remarks : "N/A";
	    			value.released_by = value.released_by != "" ? value.released_by : "N/A";
	    			value.received_by = value.received_by != "" ? value.received_by : "N/A";
	    			value.checked_by = value.checked_by != "" ? value.checked_by : "N/A";
	    			value.particular = value.particular ? value.particular : 'N/A';
	    			value.delivery_date = value.delivery_date ? moment.unix(value.delivery_date).format('MMMM DD, YYYY') : "N/A"; 
	    			return value;
	    		}); 
	    	})
				.catch(err => {
				console.error(err)
				this.isLoading = false;
			})
			.finally(fin => {
				console.log(fin)
				this.isLoading = false;	
			})
	    }, 
		 getCustomer(){

	    	axios.get(config.api_path+'/customer?page=1&limit=10000000')
	    	.then(response => {
	    		this.customerList = response.data;
	    		this.customerList.data = this.customerList.data.map((value, index)=>{
	    			value.value = value.id;
	    			value.label = value.customer_name;
	    			return value;
	    		})
	    	})

	    },

	    updateModal(item){ 
	    	this.$router.push('/booking/'+this.$route.params.id+'/update_dr/'+item.id) 
	    },

	    viewModal(item){ 
	    	this.$router.push('/booking/'+this.$route.params.id+'/view_dr/'+item.id) 
	    },

	    deleteRow(item){
	    	Swal.fire({
				icon: 'warning',
				title: 'Are you sure you want to delete this DR?', 
				showCancelButton: true,
				confirmButtonText: `Yes`, 
			}).then((result) => { 
				if (result.isConfirmed) {
					axios.delete(config.api_path+'/deliveryReceipt/'+item.id)
			    	.then(response => {
			    		this.getData(); 
			    		Swal.fire({
							title: 'Success!',
							text: "DR successfully deleted",
							icon: 'success', 
						})
			    	}) 
				}
			})  
	    }, 

	    getVendor(){

	    	axios.get(config.api_path+'/vendor?page=1&limit=10000000')
	    	.then(response => {
	    		this.vendorList = response.data;
	    		this.vendorList.data = this.vendorList.data.map((value, index)=>{
	    			value.value = value.id;
	    			value.label = value.vendor_name;
	    			return value;
	    		})
	    	})

	    },

	    getDepot(){

	    	axios.get(config.api_path+'/setting?setting_type=depot&page=1&limit=10000000')
	    	.then(response => {
	    		this.depotList = response.data;
	    		this.depotList.data = this.depotList.data.map((value, index)=>{
	    			value.value = value.id;
	    			value.label = value.setting_name;
	    			return value;
	    		})
	    	})

	    }, 

	    openApproveModal(item){
	    	this.approveModal = true;
	    	this.selectedRow = item;
	    },

	    openDispatchModal(item){
	    	this.dispatchModal = true;
	    	this.selectedRow = item;
	    },

	    openDeliverModal(item){
	    	this.deliverModal = true;
	    	this.selectedRow = item;
	    },

	    openCompleteModal(item){
	    	this.completeModal = true;
	    	this.selectedRow = item;
	    }, 

 	}
}
</script>
